import React, { lazy, Suspense } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import PublicRoute from './PublicRoute';
import PageLoader from '@/components/PageLoader';

const Login = lazy(() => import(/*webpackChunkName:'LoginPage'*/ '@/pages/auth/Login'));
const Auth = lazy(() => import(/*webpackChunkName:'LoginPage'*/ '@/layouts/Auth'));
const NotFound = lazy(() => import(/*webpackChunkName:'NotFoundPage'*/ '@/pages/NotFound'));

export default function AuthRouter() {
	const location = useLocation();
	return (
		<Suspense fallback={<PageLoader />} >
			<AnimatePresence exitBeforeEnter initial={false}>
				<Auth>
					<Switch location={location} key={location.pathname}>
						<PublicRoute path="/" component={Login} render={() => <Redirect to="/auth/login" />} exact />
						<PublicRoute component={Login} path="/auth/login" exact />
						<Route path="*" component={NotFound} render={() => <Redirect to="/notfound" />} />
					</Switch>
				</Auth>
			</AnimatePresence>
		</ Suspense >
	);
}
