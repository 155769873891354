import { combineReducers } from 'redux';

import { reducer as authReducer } from './auth';
import { reducer as crudReducer } from './crud';
import { reducer as erpReducer } from './erp';
import { reducer as settingsReducer } from './settings';
import { reducer as notifyReducer } from './notify';
import blogReducer from './blog/reducer';
import tenantReducer from './tenant/reducer';
import * as actionTypes from './auth/types';
import menuReducer from './menu/reducer';
import entityframeworkReducer from './entityframework/reducer';

// Combine all reducers.

const appReducer = combineReducers({
	auth: authReducer,
	notify: notifyReducer,
	crud: crudReducer,
	erp: erpReducer,
	settings: settingsReducer,
	menu: menuReducer,
	blog: blogReducer,
	tenant: tenantReducer,
	entityframework: entityframeworkReducer
});

const rootReducer = (state, action) => {
	if (action.type === actionTypes.LOGOUT_SUCCESS) {
		state = undefined;
	}
	return appReducer(state, action);
};

export default rootReducer;
