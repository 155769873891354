import React, { lazy, Suspense } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import PageLoader from '@/components/PageLoader';
const Logout = lazy(() => import(/*webpackChunkName:'LogoutPage'*/ '@/pages/Logout'));
const NotFound = lazy(() => import(/*webpackChunkName:'NotFoundPage'*/ '@/pages/NotFound'));
const Platform = lazy(() => import(/*webpackChunkName:'LogoutPage'*/ '@/layouts/Platform'));
const Admin = lazy(() => import(/*webpackChunkName:'LogoutPage'*/ '@/layouts/Admin'));
const Auth = lazy(() => import(/*webpackChunkName:'LogoutPage'*/ '@/layouts/Auth'));



export default function AppRouter({ isLoggedIn }) {
	return (
		<Suspense fallback={<PageLoader />}>
			<AnimatePresence exitBeforeEnter initial={false}>
				<Switch>
					<Route path="/platform/*" component={Platform} />
					<Route path="/admin/*" component={Admin} />
					<Route path="/auth/*" component={Auth} />
					<Route path="/" component={Auth} render={() => <Redirect to="/auth" />} />
					<Route path="*" component={NotFound} render={() => <Redirect to="/notfound" />} />
					<Route component={Logout} path="/logout" exact />
				</Switch>
			</AnimatePresence>
		</ Suspense >
	);
}
