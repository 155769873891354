import React from "react";
import ReactDOM from "react-dom";
import { Provider } from 'react-redux';
import App from './app';
import { AppContextProvider } from './app/appContext';
import history from '@/utils/history';
import { Router as RouterHistory } from "react-router-dom";
import store from './redux/store'
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./assets/styles/tailwind.css"


ReactDOM.render(
	<RouterHistory history={history}>
		<Provider store={store}>
			<AppContextProvider>
				<App />
			</AppContextProvider>
		</Provider>
	</RouterHistory>,
	document.getElementById("root")
);
