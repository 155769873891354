import * as actionTypes from './types';


const INITIAL_KEY_STATE = {
	value: null
};

const INITIAL_STATE = {
	value: {
		topic: null,
		search: '',
		blog: null,
		generalSettings: null,
		permissions: null,
		addTenant: null,
		isEdit: null,
		isDelete: false,
		confirmation: false,
		messageBox: false,
		modalConfirmation: false,
		attributeConfirmation: false,
		isDeleteAttribute: false,
		isDeleteModal: false,
		isTenantEdit: null,
		content: null,
		title: null,
		topicId: null,
		entity: "Entity",
		scaffolding: true,
		activeAttribute: {},
		activeTenantId: '',
		activeMetadataId: '',
		tenantData: {},
		tenantReload: false,
		attributes: [

		]
	},
	trigger: INITIAL_KEY_STATE,
};


const menuReducer = (state = INITIAL_STATE, action) => {
	const { payload } = action;
	switch (action.type) {
		case actionTypes.MODAL_OPEN_CLOSE:
			return {
				value: {
					...state.value,
					...payload
				}
			};
		default:
			return state;
	}
};

export default menuReducer;
