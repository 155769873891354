
import codeMessage from './codeMessage';
import { toast, snackbar } from 'tailwind-toast'

import Notification from '@/components/Notification/index';
const successHandler = (response, options = { notifyOnSuccess: false, notifyOnFailed: true }) => {
	const { data } = response;
	if (data && data.success === true) {
		const message = response.data && data.message;
		const successText = message || codeMessage[response.status];
		const { status } = response;
		if (options.notifyOnSuccess) {

			return toast().success(status, message).with({
				shape: 'pill',
				duration: 1000,
				speed: 1000,
				positionX: 'end',
				icon: 'fa fa-check-circle',
				positionY: 'top',
				color: 'bg-white',
				fontColor: 'white',
				fontTone: 'bg-lightBlue-900'
			}).show()

			// Notification	.config({
			// 	duration: 5,
			// });
			// Notification.success({
			// 	message: `Request success`,
			// 	description: successText,
			// });
		}
	} else {
		const message = response.data && data.message;
		const errorText = message || codeMessage[response.status];
		const { status } = response;
		if (options.notifyOnFailed) {
			return toast().danger(status, message).with({
				shape: 'pill',
				duration: 2000,
				speed: 1000,
				positionX: 'end',
				icon: 'fa fa-check-circle',
				positionY: 'top',
				color: 'bg-white',
				fontColor: 'white',
				fontTone: 'bg-lightBlue-900'
			}).show()

			// notification.config({
			// 	duration: 5,
			// });
			// notification.error({
			// 	message: `Request error ${status}`,
			// 	description: errorText,
			// });
		}
	}
};

export default successHandler;
