// import history from '@/utils/history';
import codeMessage from './codeMessage';
import { toast, snackbar } from 'tailwind-toast'


const errorHandler = (error) => {
	const { response } = error;

	if (response && response.status) {
		const message = response.data && response.data.message;

		const errorText = message || codeMessage[response.status];
		const { status } = response;

		toast().danger(status, message).with({
			shape: 'pill',
			duration: 2000,
			speed: 1000,
			positionX: 'end',
			icon: 'fa fa-check-circle',
			positionY: 'top',
			color: 'bg-white',
			fontColor: 'white',
			fontTone: 'bg-lightBlue-900'
		}).show()
		return response.data;
		// Notification.config({
		// 	duration: 10,
		// });
		// Notification.error({
		// 	message: `Request error ${status}`,
		// 	description: errorText,
		// });
	} else {
		const message = response.data && response.data.message;

		const errorText = message || codeMessage[response.status];
		const { status } = response;
		toast().danger(status, message).with({
			shape: 'pill',
			duration: 1000,
			speed: 1000,
			positionX: 'end',
			icon: 'fa fa-check-circle',
			positionY: 'top',
			color: 'bg-white',
			fontColor: 'white',
			fontTone: 'bg-lightBlue-900'
		}).show()
		return {
			success: false,
			result: null,
			message: 'Cannot connect to the server, Check your internet network',
		};
		// notification.config({
		// 	duration: 5,
		// });
		// notification.error({
		// 	message: 'No internet connection',
		// 	description: 'Cannot connect to the server, Check your internet network',
		// });

	}
};

export default errorHandler;
