import React, { lazy, Suspense } from 'react';
import { motion } from 'framer-motion';
import { Route, Redirect } from 'react-router-dom';
import * as authService from '@/auth';
const Auth = lazy(() => import(/*webpackChunkName:'LoginPage'*/ '@/layouts/Auth'));

const PublicRoute = ({ component: Component, ...rest }) => {
	return (
		// Show the component only when the admin is logged in
		// Otherwise, redirect the admin to /signin page
		<Route
			{...rest}
			render={(props) =>
				window.localStorage.getItem('isLoggedIn') ? (
					<Redirect to="/" />
				) : (
					<motion.div initial={{ x: 200 }} animate={{ x: 0 }} exit={{ scale: 0 }}>
						<Component {...props} />
					</motion.div>
				)
			}
		/>
	);
};

export default PublicRoute;
