import { API_BASE_URL } from '@/config/serverApiConfig';

import axios from 'axios';
import errorHandler from '@/request/errorHandler';
import successHandler from '@/request/successHandler';
var querystring = require('querystring');

export const login = async ({ loginData }) => {
	try {
		const response = await axios.post(`https://apidev.greypath.co/oauth/token`, querystring.stringify({ "username": loginData.email, "password": loginData.password, "grant_type": "password" }), {
			method: 'POST',
			mode: 'no-cors',
			cache: 'no-cache',
			credentials: 'include',
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'Authorization': 'Basic ZGVtb2NsaWVudDpkZW1vY2xpZW50c2VjcmV0'
			},
			redirect: 'follow',
			referrerPolicy: 'no-referrer',
		})
		const { status } = await response;

		const resp = await response
		const data = {
			success: true,
			result: resp.data
		}

		successHandler(
			{ data, status },
			{
				notifyOnSuccess: true,
				notifyOnFailed: false,
			}
		);
		return data;

	} catch (error) {
		return errorHandler(error);
	}
};

export const logout = async () => {
	try {
		window.localStorage.clear();
	} catch (error) {
		return errorHandler(error);
	}
};
